import { useSnackbar } from 'notistack';
import {
  IconButton, TextField, Dialog,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useEffect, useState } from 'react';
import Router from 'next/router';
import InputAdornment from '@mui/material/InputAdornment';

interface Props {
  onClose: () => void;
  title: string;
  text: string;
  successMessage?: string;
  messageBody?: string;
}

export const Feedback = ({
  onClose,
  title,
  text,
  successMessage,
  messageBody,
}: Props) => {
  const currentUser = useCurrentUser();
  const API = useApi();

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>(messageBody);
  const [isSaving, setIsSaving] = useState(false);

  const submit = async () => {
    try {
      setIsSaving(true);
      await API.createFeedback({
        email,
        description: `${title}: ${description}`,
        url: Router.asPath,
      });
      enqueueSnackbar(successMessage || 'Your feedback was successfully submitted!', {
        variant: 'info',
      });
      setIsSaving(false);
      setDescription(undefined);
      onClose();
    } catch (err) {
      setIsSaving(false);
      enqueueSnackbar(`There was an error submitting your feedback: ${err}`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    setEmail(currentUser?.email);
  }, [currentUser]);

  return (
    <Dialog fullWidth open aria-labelledby="form-dialog-title">
      <div className="p-6">
        <div className="absolute right-3 top-3">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>
        <h4 className="text-xl mb-2 font-bold">{title}</h4>
        <p className="mb-4">{text}</p>
        <div className="w-full">
          <div className="pb-4">
            <TextField
              required
              id="email"
              type="email"
              label="Your Email"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
              }}
            />
          </div>
          <TextField
            required
            variant="outlined"
            id="description"
            label="Description"
            multiline
            rows={4}
            value={description}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="mt-4 text-right">
            <span className="mr-2">
              <button
                className="btn-primary-outlined"
                onClick={onClose}
                type="button"
              >
                Cancel
              </button>
            </span>
            <button
              className="btn-primary"
              disabled={isSaving || !description || !email}
              onClick={submit}
              type="button"
            >
              {isSaving ? 'Saving...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
