import { useState } from 'react';
import {
  get, isString, replace, startsWith, trim,
} from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useApi } from 'lib/contexts/ApplicationState';
import { USER_TYPES } from 'lib/constants';
import {
  SearchableUser, User, UserToSave,
} from 'lib/types/users';
import { NewUserToCreditModal } from 'components/NewUserToCreditModal';
import { useSnackbar } from 'notistack';

const createUsername = (inputValue: string, sanitizedName: string) => {
  if (startsWith(inputValue, '@')) {
    return sanitizedName;
  }
  const removedPunctuation = replace(sanitizedName, /[^a-zA-Z0-9\s_.]/g, '');
  return removedPunctuation.replaceAll(' ', '_');
};

export const useSelectedUser = (initialState: User | null) => {
  const [selectedUser, setSelectedUser] = useState(initialState);
  const [error, setError] = useState(null);
  const API = useApi();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const saveToDB = async (payload: UserToSave) => {
    try {
      const createdUser = await API.createUser(payload);
      setSelectedUser(createdUser);
      setError(null);
    } catch (err) {
      setError(err as any);
      return err;
    }
    return undefined;
  };

  const formatPayload = (inputValue: string, userTypeId?: number) => {
    const sanitizedName = trim(replace(inputValue, '@', ''));
    const username = createUsername(inputValue, sanitizedName);
    return {
      name: sanitizedName,
      ...(username && { username }), // includes username property if defined.
      ...(userTypeId === USER_TYPES.company.id && { userTypeId: USER_TYPES.company.id }),
      userFromNetworkId: undefined,
    };
  };

  const addUserToGondola = async (user: SearchableUser, userTypeId?: number) => {
    const addByUsername = (username: string) => {
      const modal = showModal(NewUserToCreditModal, {
        onCancel: () => {
          modal.destroy();
        },
        onSave: async (account, type) => {
          if (account.id) {
            setSelectedUser(account as User);
            modal.destroy();
            return;
          }
          const err = await saveToDB({ ...account, userTypeId: userTypeId || type });
          if (err) {
            enqueueSnackbar(`Error adding user ${err}`, {
              variant: 'error',
            });
          } else {
            modal.destroy();
          }
        },
        username,
      });
    };

    if ((isString(user.inputValue) && user.inputValue !== '') || user.username) {
      addByUsername(user.inputValue || user.username);
    }
  };

  const onHandleSelectUser = async (user: User | SearchableUser, userTypeId?: number) => {
    const isAddValueOption = get(user, 'isCreatable', false);
    const isPostingAccount = get(user, 'isPostingAccount', false);

    if (!isAddValueOption) {
      setSelectedUser(user as User);
      return;
    }
    if (isAddValueOption && user.username && user.avatarUrl) {
      setSelectedUser(user as User);
      return;
    }
    if (isPostingAccount) {
      const payload = formatPayload(user.username, userTypeId);
      await saveToDB(payload);
      return;
    }
    await addUserToGondola(user, userTypeId);
  };

  return {
    selectedUser, onHandleSelectUser, error,
  };
};
